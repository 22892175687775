<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search"></div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            row-key="id"
            lazy
            :load="load"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            v-loading="loading"
          >
            <el-table-column
              align="left"
              prop="projectName"
              label="项目名称"
              :show-overflow-tooltip="false"
            ></el-table-column>

            <el-table-column prop="projectType" align="center" label="项目类型">
              <template slot-scope="scope">
                {{ scope.row.projectType | dict(dictData.projectType) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" label="项目状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.projectStatus) }}
              </template>
            </el-table-column>

            <el-table-column align="center" prop="agreementName" label="合同名称"></el-table-column>
            <el-table-column align="center" prop="firstParty" label="甲方"></el-table-column>
            <el-table-column
              align="center"
              prop="actualContractAmount"
              label="合同实际金额"
            ></el-table-column>
            <el-table-column align="center" prop="projectBudget" label="项目预算"></el-table-column>
            <el-table-column align="center" prop="projectPredictStartDate" label="预计项目开始时间">
              <template slot-scope="scope">
                {{ scope.row.projectPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="projectPredictEndDate" label="预计结束时间">
              <template slot-scope="scope">
                {{ scope.row.projectPredictEndDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="deptClassifyName" label="部门分类">
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="edit(scope.row)"
                  >详情</el-button
                >
                <el-button
                  v-if="
                    scope.row.operatingButton.some(r => {
                      return r == 'DELETE'
                    })
                  "
                  type="text"
                  size="small"
                  class="text_Remove_Bgc"
                  @click="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        status: '10',
      },
      pageSize: 0,
      dictData: {
        projectType: [],
        projectStatus: [],
      },
      tableData: [],
      loading: false,
      options: {},
      showEditDialog: false,
    }
  },
  watch: {},
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()

    this.$api.dict
      .listSysDictData('PROJECT_TYPE', true)
      .then(res => {
        this.dictData.projectType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_STATUS', true)
      .then(res => {
        this.dictData.projectStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    edit(row) {
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true

      this.$api.project
        .listProject(this.parameter)
        .then(res => {
          this.loading = false
          var arr = []

          res.data.records.some(r => {
            arr.push(
              r.combination({
                hasChildren: r.parentId == 0,
                children: [],
              })
            )
          })

          this.tableData = arr

          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    load(tree, treeNode, resolve) {
      console.log(tree)
      console.log(treeNode)
      this.$api.project
        .listProjectNotPage({
          parentId: tree.id,
        })
        .then(res => {
          var arr = []
          res.data.some(r => {
            arr.push(
              r.combination({
                hasChildren: false,
              })
            )
          })
          resolve(arr)
        })
        .catch(err => {
          console.log(err)
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.project
            .del(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')

              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNow >= this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNow > 1) {
                this.parameter.pageNow--
              }
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
